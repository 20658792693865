import { useContext, useEffect, useMemo, useState } from "react";

import {
  Grid,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Card,
  CardContent,
  Typography,
  Container,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteItemContext } from "../../Context/DeleteItemContext";

export default function Events() {
  const deleteContext = useContext(DeleteItemContext);

  const [showCompletedEvents, setShowCompletedEvents] = useState(false);
  const [pendingEvents, setPendingEvents] = useState([]);
  const [completedEvents, setCompletedEvents] = useState([]);
  const [events, setEvents] = useState([]);

  const countPendingEvents = pendingEvents.length;
  const countCompletedEvents = completedEvents.length;

  const handleShowPendingEvents = () => {
    setShowCompletedEvents(false);
    setEvents(pendingEvents);
  };

  const handleShowCompletedEvents = () => {
    setShowCompletedEvents(true);
    setEvents(completedEvents);
  };

  const handleEventDelete = (id, name) => {
    const eventDeleteURL = `/Events/DeleteEvent.php?id=${id}`;
    deleteContext.handleShowDeleteDialog(name, eventDeleteURL);
  };

  useEffect(() => {
    const fetchPendingEvents = async () => {
      try {
        const response = await axios.get("/Events/Events.php?status=pending");
        setPendingEvents(response.data.data);
        setEvents(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchCompletedEvents = async () => {
      try {
        const response = await axios.get("/Events/Events.php?status=completed");
        setCompletedEvents(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPendingEvents();
    fetchCompletedEvents();
  }, []);

  const columns = useMemo(
    () => [
      {
        field: "imageurl",
        headerName: "Image",
        minWidth: 90,
        renderCell: (params) => <Avatar src={params.row.imageurl} />,
        sortable: false,
        filterable: false,
      },
      { field: "date", headerName: "Event Date", minWidth: 100 },
      { field: "name", headerName: "Event Name", flex: 1 },
      { field: "starttime", headerName: "Start Time", minWidth: 100 },
      { field: "endtime", headerName: "End Time", minWidth: 100 },
      { field: "mode", headerName: "Mode", minWidth: 100 },
      { field: "type", headerName: "Type", minWidth: 100 },
      {
        field: "rateincl",
        headerName: "Rate (Inclusive of Tax)",
        minWidth: 150,
        align: "right",
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 220,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <div>
            <Button
              variant="contained"
              color="warning"
              component={Link}
              to={`/Events/RegisteredUsers/${params.row.id}`}
            >
              <GroupIcon />
            </Button>

            <Button
              component={Link}
              variant="contained"
              sx={{ ml: 1 }}
              to={`/Events/EditEvent/${params.row.id}`}
            >
              <EditIcon />
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ ml: 1 }}
              onClick={() => handleEventDelete(params.row.id, params.row.name)}
            >
              <DeleteIcon />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Container>
      <Grid
        container
        columnSpacing={2}
        sx={{ display: "flex" }}
        justifyContent="center"
      >
        <Grid item xs={12} md={6} lg={3}>
          <Card
            onClick={handleShowPendingEvents}
            sx={{
              cursor: "pointer",
              backgroundColor: !showCompletedEvents ? "ButtonHighlight" : "",
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.primary"
                gutterBottom
              >
                Pending Events
              </Typography>
              <Typography variant="h6">{countPendingEvents}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Card
            onClick={handleShowCompletedEvents}
            sx={{
              cursor: "pointer",
              backgroundColor: showCompletedEvents ? "ButtonHighlight" : "",
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.primary"
                gutterBottom
              >
                Completed Events
              </Typography>
              <Typography variant="h6">{countCompletedEvents}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid sx={{ mt: 2 }}>
        <Grid item>
          <Button
            component={Link}
            to="/Events/AddEvent"
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2 }}>
        <DataGrid
          columns={columns}
          rows={events}
          getRowId={(row) => row.id}
          initialState={{
            ...events.initialState,
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10]}
        />
      </Box>
    </Container>
  );
}
