import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

export default function HelpContents() {
  const [helpcontents, setHelpContents] = useState([]);

  useEffect(() => {
    const fetchHelpContents = async () => {
      try {
        const response = await axios.get("/HelpCenter/Content/Contents.php");
        setHelpContents(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchHelpContents();
  }, []);

  const columns = useMemo(
    () => [
      { field: "index", headerName: "Index", filterable: false },
      {
        field: "videourl",
        headerName: "Video URL",
        minWidth: 180,
        renderCell: (params) => (
          <Box
            component="iframe"
            sx={{ width: "100%", height: "100%" }}
            src={params.row.videourl}
          />
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: "name",
        headerName: "Help Category Name",
        flex: 1,
        minWidth: 400,
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <div>
            <Button
              component={Link}
              variant="contained"
              to={`/HelpCenter/HelpContents/EditHelpContent/${params.row.id}`}
            >
              <EditIcon />
            </Button>
            <Button variant="contained" color="error" sx={{ ml: 1 }}>
              <DeleteIcon />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Container>
      <Button
        component={Link}
        to="/HelpCenter/HelpContents/AddHelpContent"
        variant="contained"
        size="large"
        startIcon={<AddIcon />}
      >
        Add
      </Button>

      {helpcontents.map((item) => (
        <Box key={item.id} sx={{ mt: 1 }}>
          <Typography variant="h5" color="GrayText" sx={{ mb: 1 }}>
            {item.category}
          </Typography>
          <DataGrid
            columns={columns}
            rows={item.content}
            rowHeight={90}
            initialState={{
              ...item.content.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 20]}
          />
        </Box>
      ))}
    </Container>
  );
}
