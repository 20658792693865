import {
  Box,
  Card,
  Button,
  Container,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { DataGrid } from "@mui/x-data-grid";

export default function EventRegisteredUsers() {
  const [eventData, setEventData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(
          `/Events/Registration/RegisteredUsers.php?id=${id}`
        );
        setEventData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchEvent();
  }, [id]);

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", flex: 1, minWidth: 200 },
      { field: "company", headerName: "Company", minWidth: 200 },
      { field: "mobile", headerName: "Mobile", minWidth: 100 },
      { field: "email", headerName: "Email", minWidth: 250 },
      { field: "seats", headerName: "Registered Seats", minWidth: 150 },
      { field: "rayzorpayid", headerName: "rayzorpayid", minWidth: 200 },
    ],
    []
  );

  return (
    <Container>
      {eventData && (
        <Box>
          <Typography variant="h6">Event Details</Typography>
          <Divider />
          <Grid container spacing={2} sx={{ pt: 1, pb: 1 }}>
            <Grid item xs={4} md={2}>
              <Typography align="left" color="blue">
                Event Name
              </Typography>
            </Grid>
            <Grid item xs={6} md={10}>
              <Typography align="left">{eventData.name}</Typography>
            </Grid>
          </Grid>
          <Divider />

          <Grid container spacing={2} sx={{ pt: 1, pb: 1 }}>
            <Grid item xs={4} md={2}>
              <Typography align="left" color="blue">
                Date
              </Typography>
            </Grid>
            <Grid item xs={6} md={10}>
              <Typography align="left">{eventData.date}</Typography>
            </Grid>
          </Grid>
          <Divider />

          <Grid container spacing={2} sx={{ pt: 1, pb: 1 }}>
            <Grid item xs={4} md={2}>
              <Typography align="left" color="blue">
                Time
              </Typography>
            </Grid>
            <Grid item xs={6} md={10}>
              <Typography align="left">
                {eventData.starttime} to {eventData.endtime}
              </Typography>
            </Grid>
          </Grid>
          <Divider />

          <Grid
            container
            sx={{ mt: 3, display: "flex", justifyContent: "end" }}
          >
            <Grid item>
              <CSVLink
                data={eventData.registrations}
                filename={`User Registration for Event ${eventData.name}.csv`}
              >
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<DownloadIcon />}
                >
                  Excel
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <DataGrid
              columns={columns}
              rows={eventData.registrations}
              getRowId={(row) => row.id}
            />
          </Box>
        </Box>
      )}
    </Container>
  );
}
