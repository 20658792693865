import {
  AppBar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Drawer,
  Avatar,
  ListItemIcon,
  Collapse,
  MenuItem,
  Menu,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import HighlightIcon from "@mui/icons-material/Highlight";
import ProductsIcon from "@mui/icons-material/ShoppingCart";
import SolutionsIcon from "@mui/icons-material/GraphicEq";
import CloudIcon from "@mui/icons-material/Cloud";
import EventIcon from "@mui/icons-material/Event";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import SupportIcon from "@mui/icons-material/Support";
import StarBorder from "@mui/icons-material/StarBorder";
import UserIcon from "@mui/icons-material/Group";
import { Link, useNavigate } from "react-router-dom";

const drawerWidth = 270;

export default function Sidebar() {
  const navigate = useNavigate();

  const sideMenus = [
    { name: "Tally Highlight", icon: <HighlightIcon />, path: "/Hightlights" },
    { name: "Products", icon: <ProductsIcon />, path: "/Products" },
    { name: "Solutions", icon: <SolutionsIcon />, path: "/Solutions" },
    { name: "Aws Plans", icon: <CloudIcon />, path: "/AwsPlans" },
    { name: "Events", icon: <EventIcon />, path: "/Events" },
    { name: "Branches", icon: <ContactPageIcon />, path: "/Contacts" },
    { name: "Users", icon: <UserIcon />, path: "/Users" },
  ];

  const [mobileOpen, setMobileOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const [showUserMenu, setShowUserMenu] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const drawer = (
    <div>
      <Toolbar
        textalign="center"
        sx={{ backgroundColor: "#1975d1ff", boxShadow: 5 }}
      >
        <Typography variant="h6" noWrap sx={{ color: "white" }}>
          Kiran Infotech
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {sideMenus.map((item, i) => (
          <ListItem disablePadding key={i}>
            <ListItemButton component={Link} to={item.path}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItemButton onClick={handleShowMore}>
          <ListItemIcon>
            <HelpCenterIcon />
          </ListItemIcon>
          <ListItemText primary="Help Center" />
          {showMore ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={showMore} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              component={Link}
              to="/HelpCenter/HelpCategories"
            >
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Help Categories" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              component={Link}
              to="/HelpCenter/HelpContents"
            >
              <ListItemIcon>
                <SupportIcon />
              </ListItemIcon>
              <ListItemText primary="Help Contents" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <Divider />
    </div>
  );

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            align="left"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <div>
            <Avatar
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleUserMenu}
              color="inherit"
            ></Avatar>
            <Menu
              id="menu-appbar"
              anchorEl={showUserMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={showUserMenu}
              onClose={handleUserMenu}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
