import { Box, Grid, Button, Avatar, TextField, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudCircle";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AddSolution() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [solution_image, setSolution_Image] = useState(null);
  const [image_preview, setImage_Preview] = useState(null);
  const [description, setDescription] = useState("");
  const [videourl, setVideourl] = useState("");
  const [rate, setRate] = useState("");
  const [gst, setGST] = useState("");

  const setImageFile = (e) => {
    setSolution_Image(e.target.files[0]);
    setImage_Preview(URL.createObjectURL(e.target.files[0]));
  };

  const setChangeVideoURL = (e) => {
    const originalLink = e.target.value;
    const embeddedLink = originalLink.replace("watch?v=", "embed/");
    setVideourl(embeddedLink);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("index", 1);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("solution_image", solution_image);
    formData.append("videourl", videourl);
    formData.append("rate", rate);
    formData.append("gst", gst);

    axios
      .post("/Solutions/AddSolution.php", formData)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(String(error), { variant: "error" });
      });

    navigate("/Solutions");
  };

  return (
    <Container component="form" onSubmit={handleSubmit}>
      <Grid container columnSpacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={12} sx={{ mb: { xs: 2 } }}>
          <TextField
            label="Solution Name"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={12} sx={{ mb: { xs: 2 } }}>
          <TextField
            label="Solution Description"
            fullWidth
            multiline
            required
            maxRows={4}
            minRows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item md={6} xs={12}>
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Avatar
              src={image_preview}
              variant="square"
              sx={{ height: 180, width: 180 }}
            ></Avatar>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                accept="image/png, image/jpeg"
                onChange={setImageFile}
              />
            </Button>
          </Box>
        </Grid>

        <Grid
          item
          display="flex"
          direction="column"
          justifyContent="center"
          md={6}
          xs={12}
        >
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <iframe
              sx={{ height: 180, width: 180 }}
              src={videourl}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Box>
          <Box sx={{ mt: 1 }}>
            <TextField
              label="Youtube Video URL"
              fullWidth
              size="small"
              value={videourl}
              onChange={setChangeVideoURL}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6} sx={{ mb: { xs: 2 } }}>
          <TextField
            label="Rate (Exclusive GST)"
            fullWidth
            type="number"
            required
            value={rate}
            onChange={(e) => setRate(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="GST (%)"
            fullWidth
            type="number"
            required
            value={gst}
            onChange={(e) => setGST(e.target.value)}
          />
        </Grid>
      </Grid>
      <Button variant="contained" type="submit">
        Submit
      </Button>
    </Container>
  );
}
