import "./App.css";
import Login from "./Pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import ProtectRoute from "./ProtectRoute";
import Products from "./Pages/Products/Products";
import AddProduct from "./Pages/Products/AddProduct";
import EditProduct from "./Pages/Products/EditProduct";
import Solutions from "./Pages/Solutions/Solutions";
import AddSolution from "./Pages/Solutions/AddSolution";
import EditSolution from "./Pages/Solutions/EditSolution";
import AwsPlans from "./Pages/AwsPlans/AwsPlans";
import AddAwsPlan from "./Pages/AwsPlans/AddAwsPlan";
import EditAwsPlan from "./Pages/AwsPlans/EditAwsPlan";
import Events from "./Pages/Events/Events";
import AddEvent from "./Pages/Events/AddEvent";
import EditEvent from "./Pages/Events/EditEvent";
import NoPage from "./Pages/NoPage";
import Contacts from "./Pages/Contacts/Contacts";
import AddContact from "./Pages/Contacts/AddContact";
import EditContact from "./Pages/Contacts/EditContact";
import HelpCategories from "./Pages/Help Center/Help Category/HelpCategories";
import EventRegisteredUsers from "./Pages/Events/EventRegisteredUsers";
import AddHelpCategory from "./Pages/Help Center/Help Category/AddHelpCategory";
import EditHelpCategory from "./Pages/Help Center/Help Category/EditHelpCategory";
import HelpContents from "./Pages/Help Center/Help Content/HelpContents";
import AddHelpContent from "./Pages/Help Center/Help Content/AddHelpContent";
import EditHelpContent from "./Pages/Help Center/Help Content/EditHelpContent";
import Users from "./Pages/Users/Users";
import AddUser from "./Pages/Users/AddUser";
import EditUser from "./Pages/Users/EditUser";
import Highlights from "./Pages/Highlights/Highlights";
import AddHightlight from "./Pages/Highlights/AddHightlight";
import EditHightlight from "./Pages/Highlights/EditHightlight";
import { DeleteDialogState } from "./Context/DeleteItemContext";

function App() {
  return (
    <div className="App">
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <BrowserRouter>
          <DeleteDialogState>
            <Routes>
              <Route path="/" Component={Login} />

              <Route
                path="/Hightlights"
                element={<ProtectRoute Component={Highlights} />}
              />

              <Route
                path="/Hightlights/AddHightLight"
                element={<ProtectRoute Component={AddHightlight} />}
              />
              <Route
                path="/Hightlights/EditHightLight/:id"
                element={<ProtectRoute Component={EditHightlight} />}
              />

              <Route
                path="/Products"
                element={<ProtectRoute Component={Products} />}
              />
              <Route
                path="/Products/AddProduct"
                element={<ProtectRoute Component={AddProduct} />}
              />
              <Route
                path="/Products/EditProduct/:id"
                element={<ProtectRoute Component={EditProduct} />}
              />
              <Route
                path="/Solutions"
                element={<ProtectRoute Component={Solutions} />}
              />
              <Route
                path="/Solutions/AddSolution"
                element={<ProtectRoute Component={AddSolution} />}
              />
              <Route
                path="/Solutions/EditSolution/:id"
                element={<ProtectRoute Component={EditSolution} />}
              />
              <Route
                path="/AwsPlans"
                element={<ProtectRoute Component={AwsPlans} />}
              />
              <Route
                path="/AwsPlans/AddAwsPlan"
                element={<ProtectRoute Component={AddAwsPlan} />}
              />
              <Route
                path="/AwsPlans/EditAwsPlan/:id"
                element={<ProtectRoute Component={EditAwsPlan} />}
              />
              <Route
                path="/Events"
                element={<ProtectRoute Component={Events} />}
              />
              <Route
                path="/Events/AddEvent"
                element={<ProtectRoute Component={AddEvent} />}
              />
              <Route
                path="/Events/EditEvent/:id"
                element={<ProtectRoute Component={EditEvent} />}
              />

              <Route
                path="/Events/RegisteredUsers/:id"
                element={<ProtectRoute Component={EventRegisteredUsers} />}
              />

              <Route
                path="/Contacts"
                element={<ProtectRoute Component={Contacts} />}
              />

              <Route
                path="/Contacts/AddContact"
                element={<ProtectRoute Component={AddContact} />}
              />

              <Route
                path="/Contacts/EditContact/:id"
                element={<ProtectRoute Component={EditContact} />}
              />

              <Route
                path="/HelpCenter/HelpCategories"
                element={<ProtectRoute Component={HelpCategories} />}
              />

              <Route
                path="/HelpCenter/HelpCategories/AddHelpCategory"
                element={<ProtectRoute Component={AddHelpCategory} />}
              />
              <Route
                path="/HelpCenter/HelpCategories/EditHelpCategory/:id"
                element={<ProtectRoute Component={EditHelpCategory} />}
              />

              <Route
                path="/HelpCenter/HelpContents"
                element={<ProtectRoute Component={HelpContents} />}
              />

              <Route
                path="/HelpCenter/HelpContents/AddHelpContent"
                element={<ProtectRoute Component={AddHelpContent} />}
              />

              <Route
                path="/HelpCenter/HelpContents/EditHelpContent/:id"
                element={<ProtectRoute Component={EditHelpContent} />}
              />

              <Route
                path="/Users"
                element={<ProtectRoute Component={Users} />}
              />

              <Route
                path="/Users/AddUser"
                element={<ProtectRoute Component={AddUser} />}
              />

              <Route
                path="/Users/EditUser/:id"
                element={<ProtectRoute Component={EditUser} />}
              />

              <Route path="*" exact={true} Component={NoPage} />
            </Routes>
          </DeleteDialogState>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}

export default App;
