import { Avatar, Box, Button, Container, Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudCircle";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function EditHelpCategory() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id } = useParams();
  const [index, setIndex] = useState("");
  const [name, setName] = useState("");
  const [image_preview, setImage_Preview] = useState(null);
  const [categoryImage, setCategoryImage] = useState(null);

  useEffect(() => {
    const fetchHelpCategory = async () => {
      try {
        const response = await axios.get(
          `/HelpCenter/Category/Category.php?id=${id}`
        );
        setIndex(response.data.data.index);
        setName(response.data.data.name);
        setImage_Preview(response.data.data.imageurl);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchHelpCategory();
  }, [id]);

  const setImageFile = (e) => {
    setCategoryImage(e.target.files[0]);
    setImage_Preview(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("id", id)
    formData.append("index", 1);
    formData.append("name", name);
    formData.append("helpcategory_image", categoryImage);

    axios
      .post("/HelpCenter/Category/EditCategory.php", formData)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(String(error), { variant: "error" });
      });

    navigate("/HelpCenter/HelpCategories");
  };

  return (
    <Container component="form" onSubmit={handleSubmit}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              src={image_preview}
              variant="square"
              sx={{ height: 180, width: 180 }}
            ></Avatar>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                accept="image/png, image/jpeg"
                onChange={setImageFile}
              />
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 1 }} columnSpacing={2}>
        <Grid item xs={12} md={2}>
          <TextField
            label="Index"
            fullWidth
            value={index}
            onChange={(e) => setIndex(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={10}>
          <TextField
            label="Help Category Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Button type="submit" variant="contained" sx={{ mt: 1 }}>
        Submit
      </Button>
    </Container>
  );
}
