import { Box, Button, Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

export default function Users() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/Users/Users.php");
        setUsers(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUsers();
  }, []);

  const columns = useMemo(
    () => [
      { field: "name", headerName: "User Name", flex: 1, minWidth: 400 },
      { field: "role", headerName: "Role", minWidth: 200 },
      { field: "status", headerName: "Status", minWidth: 200 },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <div>
            <Button
              component={Link}
              variant="contained"
              to={`/Users/EditUser/${params.row.id}`}
            >
              <EditIcon />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Container>
      <Box display="flex" justifyContent="end">
        <Button
          component={Link}
          startIcon={<AddIcon />}
          variant="contained"
          size="large"
          to="/Users/AddUser"
        >
          Add
        </Button>
      </Box>

      <Box sx={{ mt: 1 }}>
        <DataGrid
          columns={columns}
          rows={users}
          initialState={{
            ...users.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20]}
        />
      </Box>
    </Container>
  );
}
