import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AddHelpContent() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [helpCategories, setHelpCategories] = useState([]);
  const [helpCategory, setHelpCategory] = useState(null);
  const [helpCategoryId, setHelpCategoryID] = useState("");
  const [name, setName] = useState("");
  const [videourl, setVideourl] = useState("");

  useEffect(() => {
    const fetchHelpCategories = async () => {
      try {
        const response = await axios.get("/HelpCenter/Category/Category.php");
        setHelpCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchHelpCategories();
  }, []);

  const setChangeVideoURL = (e) => {
    const originalLink = e.target.value;
    const embeddedLink = originalLink.replace("watch?v=", "embed/");
    setVideourl(embeddedLink);
  };

  const handleHelpCategory = (selected) => {
    setHelpCategory(selected);
    setHelpCategoryID(selected.id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("index", 1);
    formData.append("name", name);
    formData.append("videourl", videourl);
    formData.append("categoryid", helpCategoryId);

    axios
      .post("/HelpCenter/Content/AddContent.php", formData)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(String(error), { variant: "error" });
      });

    navigate("/HelpCenter/HelpContents");
  };

  return (
    <Container component="form" onSubmit={handleSubmit}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={12}>
          <Autocomplete
            fullWidth
            options={helpCategories}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Avatar sx={{ mr: 2 }} src={option.imageurl} alt="" />
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Choose a Help Category" />
            )}
            value={helpCategory}
            onChange={(event, newValue) => handleHelpCategory(newValue)}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label="Help Content Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={12}>
          <Box component="iframe" src={videourl} />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label="Youtube Video URL"
            value={videourl}
            onChange={setChangeVideoURL}
          />
        </Grid>
      </Grid>

      <Button type="submit" variant="contained" sx={{ mt: 3 }}>
        Submit
      </Button>
    </Container>
  );
}
