import { Box, Button, Container } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

export default function Contacts() {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get("/Contacts/Contacts.php");
        setContacts(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchContacts();
  }, []);

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Office Name", flex: 1 },
      { field: "phone", headerName: "Phone", minWidth: 150 },
      { field: "mobile", headerName: "Mobile", minWidth: 150 },
      { field: "supportno", headerName: "Exotel No", minWidth: 100 },
      { field: "whatsappno", headerName: "WhatsApp No", minWidth: 100 },
      { field: "email", headerName: "Email", minWidth: 200 },
      {
        field: "actions",
        headerName: "Actions",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <div>
            <Button
              component={Link}
              variant="contained"
              to={`/Contacts/EditContact/${params.row.id}`}
            >
              <EditIcon />
            </Button>
            <Button variant="contained" color="error" sx={{ ml: 1 }}>
              <DeleteIcon />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Container>
      <Button
        component={Link}
        to="/Contacts/AddContact"
        variant="contained"
        size="large"
        startIcon={<AddIcon />}
      >
        Add
      </Button>
      <Box sx={{ mt: 2 }}>
        <DataGrid
          columns={columns}
          rows={contacts}
          getRowId={(row) => row.id}
          initialState={{
            ...contacts.initialState,
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10]}
        />
      </Box>
    </Container>
  );
}
