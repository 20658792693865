import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Checkbox,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudCircle";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const languages = ["English", "Kannada", "Marathi", "Hindi"];

export default function EditEvent() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();

  const [event_Image, setEvent_Image] = useState("");
  const [image_preview, setImage_Preview] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [languageName, setLanguageName] = useState([]);
  const [eventMode, setEventMode] = useState("");
  const [eventType, setEventType] = useState("");
  const [maxSeats, setMaxSeats] = useState("");
  const [availableseats, setAvailableSeats] = useState("");
  const [agenda, setAgenda] = useState("");
  const [venue, setVenue] = useState("");
  const [rate, setRate] = useState("");
  const [gst, setGST] = useState("");

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`/Events/Events.php?id=${id}`);
        setName(response.data.data.name);
        setDate(response.data.data.date);
        setStartTime(response.data.data.starttime);
        setEndTime(response.data.data.endtime);
        setEventMode(response.data.data.mode);
        setEventType(response.data.data.type);
        setLanguageName(response.data.data.language);
        setImage_Preview(response.data.data.imageurl);
        setMaxSeats(response.data.data.maxseats);
        setAvailableSeats(response.data.data.availableseats);
        setVenue(response.data.data.venue);
        setAgenda(response.data.data.agenda);
        setRate(response.data.data.rate);
        setGST(response.data.data.gst);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEvent();
  }, [id]);

  const handleImageChange = (e) => {
    setEvent_Image(e.target.files[0]);
    setImage_Preview(URL.createObjectURL(e.target.files[0]));
  };

  const handleLanguageChange = (event) => {
    const {
      target: { value },
    } = event;
    setLanguageName(typeof value === "string" ? value.split(",") : value);

    console.log(languageName)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", id);
    formData.append("event_image", event_Image);
    formData.append("name", name);
    formData.append("date", date);
    formData.append("starttime", startTime);
    formData.append("endtime", endTime);
    formData.append("mode", eventMode);
    formData.append("type", eventType);
    formData.append("language", languageName);
    formData.append("maxseats", maxSeats);
    formData.append("availableseats", availableseats);
    formData.append("agenda", agenda);
    formData.append("venue", venue);
    formData.append("rate", rate);
    formData.append("gst", gst);

    axios
      .post("/Events/EditEvent.php", formData)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(String(error), { variant: "error" });
      });

    navigate("/Events");
  };

  return (
    <Container component="form" onSubmit={handleSubmit}>
      <Grid container columnSpacing={2}>
        <Grid item md={12} xs={12}>
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Avatar
              src={image_preview}
              variant="square"
              sx={{ height: 90, width: 90 }}
            ></Avatar>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleImageChange}
              />
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6} sx={{ mb: { xs: 2 } }}>
          <TextField
            label="Event Name"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: { xs: 2 } }}>
          <TextField
            type="date"
            label="Event Date"
            fullWidth
            value={date}
            required
            onChange={(e) => setDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={3} sx={{ mb: { xs: 2 } }}>
          <TextField
            type="time"
            label="Start Time"
            fullWidth
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: { xs: 2 } }}>
          <TextField
            type="time"
            label="End Time"
            fullWidth
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: { xs: 2 } }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Event Mode</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Event Mode"
              value={eventMode}
              onChange={(e) => setEventMode(e.target.value)}
            >
              <MenuItem value="Online">Online</MenuItem>
              <MenuItem value="Offline">Offline</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: { xs: 2 } }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Event Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Event Type"
              value={eventType}
              onChange={(e) => setEventType(e.target.value)}
            >
              <MenuItem value="Free">Free</MenuItem>
              <MenuItem value="Paid">Paid</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={2} sx={{ mb: { xs: 2 } }}>
          <TextField
            type="number"
            label="Maximum Seats"
            fullWidth
            value={maxSeats}
            onChange={(e) => setMaxSeats(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ mb: { xs: 2 } }}>
          <TextField
            type="number"
            label="Available Seats"
            fullWidth
            value={availableseats}
            onChange={(e) => setAvailableSeats(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ mb: { xs: 2 } }}>
          <TextField
            disabled={eventType === "Free"}
            type="number"
            label="Rate (Excluding GST) / Person"
            fullWidth
            value={rate}
            onChange={(e) => setRate(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: { xs: 2 } }}>
          <TextField
            disabled={eventType === "Free"}
            type="number"
            label="GST (%)"
            fullWidth
            value={gst}
            onChange={(e) => setGST(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: { xs: 2 } }}>
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">Language</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={languageName}
              onChange={handleLanguageChange}
              input={<OutlinedInput label="Language" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {languages.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={languageName.includes(name)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6} sx={{ mb: { xs: 2 } }}>
          <TextField
            type="number"
            label="Agenda"
            fullWidth
            multiline
            minRows={4}
            value={agenda}
            onChange={(e) => setAgenda(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: { xs: 2 } }}>
          <TextField
            type="number"
            label="Venue / Link"
            fullWidth
            multiline
            minRows={4}
            value={venue}
            onChange={(e) => setVenue(e.target.value)}
          />
        </Grid>
      </Grid>
      <Button variant="contained" type="submit">
        Submit
      </Button>
    </Container>
  );
}
