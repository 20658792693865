import { Box, Button, Container, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function EditAwsPlan() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();

  const [name, setName] = useState("");
  const [users, setUsers] = useState("");
  const [ram, setRam] = useState("");
  const [duration, setDuration] = useState("");
  const [storage, setStorage] = useState("");
  const [backupstorage, setBackupStorage] = useState("");
  const [customerprice, setCustomerPrice] = useState("");
  const [partnerprice, setPartnerPrice] = useState("");
  const [customerhourlycharges, setCustomerHourlyCharges] = useState("");
  const [partnerhourlycharges, setPartnerHourlyCharges] = useState("");
  const [gst, setGST] = useState("");

  useEffect(() => {
    const fetchAwsPlan = async () => {
      try {
        const response = await axios.get(`/AwsPlans/AwsPlans.php?id=${id}`);
        setName(response.data.data.name);
        setUsers(response.data.data.users);
        setRam(response.data.data.ram);
        setDuration(response.data.data.duration);
        setStorage(response.data.data.storage);
        setBackupStorage(response.data.data.backupstorage);
        setCustomerPrice(response.data.data.customerprice);
        setPartnerPrice(response.data.data.partnerprice);
        setCustomerHourlyCharges(response.data.data.customerhourlycharges);
        setPartnerHourlyCharges(response.data.data.partnerhourlycharges);
        setGST(response.data.data.gst);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAwsPlan();
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", id);
    formData.append("name", name);
    formData.append("users", users);
    formData.append("ram", ram);
    formData.append("storage", storage);
    formData.append("backupstorage", backupstorage);
    formData.append("duration", duration);
    formData.append("customerprice", customerprice);
    formData.append("partnerprice", partnerprice);
    formData.append("customerhourlycharges", customerhourlycharges);
    formData.append("partnerhourlycharges", partnerhourlycharges);
    formData.append("gst", gst);

    axios
      .post("/AwsPlans/EditAwsPlan.php", formData)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(String(error), { variant: "error" });
      });

    navigate("/AwsPlans");
  };

  return (
    <Container component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mb:1}}>
          <TextField
            label="Aws Plan Name"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1}}>
          <TextField
            label="No of Users"
            fullWidth
            required
            type="number"
            value={users}
            onChange={(e) => setUsers(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mb:1}}>
          <TextField
            label="RAM"
            fullWidth
            required
            value={ram}
            onChange={(e) => setRam(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb:1}}>
          <TextField
            label="Duration"
            fullWidth
            required
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mb:1}}>
          <TextField
            label="Storage"
            fullWidth
            required
            value={storage}
            onChange={(e) => setStorage(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb:1}}>
          <TextField
            label="Backup Storage"
            fullWidth
            required
            value={backupstorage}
            onChange={(e) => setBackupStorage(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mb:1}}>
          <TextField
            label="Customer Monthly (Taxable Value)"
            fullWidth
            required
            type="number"
            inputProps={{ min: 1, step: "any" }}
            value={customerprice}
            onChange={(e) => setCustomerPrice(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb:1}}>
          <TextField
            label="Partner Monthly (Taxable Value)"
            fullWidth
            required
            type="number"
            inputProps={{ min: 1, step: "any" }}
            value={partnerprice}
            onChange={(e) => setPartnerPrice(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mb:1}}>
          <TextField
            label="Customer Additional Hour Charges"
            fullWidth
            required
            type="number"
            value={customerhourlycharges}
            inputProps={{ min: 1, step: "any" }}
            onChange={(e) => setCustomerHourlyCharges(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb:1}}>
          <TextField
            label="Partner Additional Hour Charges"
            fullWidth
            required
            type="number"
            value={partnerhourlycharges}
            inputProps={{ min: 1, step: "any" }}
            onChange={(e) => setPartnerHourlyCharges(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mb:1}}>
          <TextField
            label="GST (%)"
            fullWidth
            required
            type="number"
            value={gst}
            onChange={(e) => setGST(e.target.value)}
          />
        </Grid>
      </Grid>
      <Button variant="contained" type="submit">
        Submit
      </Button>
    </Container>
  );
}
