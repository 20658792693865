import { Box, Button, Container, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function EditContact() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [supportno, setSupportNo] = useState("");
  const [email, setEmail] = useState("");
  const [maplink, setMapLink] = useState("");
  const [longitude, setLongitude] = useState("");
  const [langitude, setLangitude] = useState("");
  const [bankname, setBankName] = useState("");
  const [accountno, setAccountNo] = useState("");
  const [ifsccode, setIFSCcode] = useState("");
  const [branch, setBranch] = useState("");
  const [bankUPI, setBankUPI] = useState("");

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const response = await axios.get(`/Contacts/Contacts.php?id=${id}`);
        setName(response.data.data.name);
        setAddress(response.data.data.address);
        setPhone(response.data.data.phone);
        setMobile(response.data.data.mobile);
        setSupportNo(response.data.data.supportno);
        setWhatsapp(response.data.data.whatsappno);
        setEmail(response.data.data.email);
        setLongitude(response.data.data.longitude);
        setLangitude(response.data.data.langitude);
        setMapLink(response.data.data.mapurl);
        setBankName(response.data.data.bankname);
        setAccountNo(response.data.data.accountno);
        setIFSCcode(response.data.data.ifsc);
        setBranch(response.data.data.branch);
        setBankUPI(response.data.data.upiid);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchContact();
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("id", id);
    formData.append("name", name);
    formData.append("address", address);
    formData.append("phone", phone);
    formData.append("mobile", mobile);
    formData.append("supportno", supportno);
    formData.append("whatsappno", whatsapp);
    formData.append("email", email);
    formData.append("longitude", longitude);
    formData.append("langitude", langitude);
    formData.append("mapurl", maplink);
    formData.append("bankname", bankname);
    formData.append("accountno", accountno);
    formData.append("ifsc", ifsccode);
    formData.append("branch", branch);
    formData.append("upiid", bankUPI);

    axios
      .post("/Contacts/EditContact.php", formData)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(String(error), { variant: "error" });
      });

    navigate("/Contacts");
  };

  return (
    <Container component="form" onSubmit={handleSubmit}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={12} sx={{ mb: 1}}>
          <TextField
            label="Office Name"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={12} sx={{ mb: 1}}>
          <TextField
            label="Address"
            fullWidth
            multiline
            required
            maxRows={3}
            minRows={3}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={3} sx={{ mb: 1}}>
          <TextField
            label="Phone Number"
            fullWidth
            required
            size="small"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1}}>
          <TextField
            label="Mobile Number"
            fullWidth
            required
            size="small"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1}}>
          <TextField
            label="WhatsApp Number"
            fullWidth
            required
            size="small"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1}}>
          <TextField
            label="Support Number"
            fullWidth
            required
            size="small"
            value={supportno}
            onChange={(e) => setSupportNo(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mb: 1}}>
          <TextField
            label="Email ID"
            fullWidth
            required
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 1}}>
          <TextField
            label="Google Map Link"
            fullWidth
            required
            size="small"
            value={maplink}
            onChange={(e) => setMapLink(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mb: 1}}>
          <TextField
            label="Longitude"
            fullWidth
            required
            size="small"
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 1}}>
          <TextField
            label="Langitude"
            fullWidth
            required
            size="small"
            value={langitude}
            onChange={(e) => setLangitude(e.target.value)}
          />
        </Grid>
      </Grid>

      <Box
        component="fieldset"
        sx={{ borderRadius: 1, borderColor: "grey.300" }}
      >
        <legend>Bank Details</legend>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={6} sx={{ mb: 1}}>
            <TextField
              label="Bank Name"
              fullWidth
              required
              size="small"
              value={bankname}
              onChange={(e) => setBankName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 1}}>
            <TextField
              label="Account Number"
              fullWidth
              required
              size="small"
              value={accountno}
              onChange={(e) => setAccountNo(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={2} >
          <Grid item xs={12} md={6} sx={{ mb: 1}}>
            <TextField
              label="IFSC Code"
              fullWidth
              required
              size="small"
              value={ifsccode}
              onChange={(e) => setIFSCcode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 1}}>
            <TextField
              label="Branch"
              fullWidth
              required
              size="small"
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={6} sx={{ mb: 1}}>
            <TextField
              label="UPI ID"
              fullWidth
              required
              size="small"
              value={bankUPI}
              onChange={(e) => setBankUPI(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      <Button type="submit" variant="contained" sx={{ mt: 1 }}>
        Submit
      </Button>
    </Container>
  );
}
