import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Avatar,
  Typography,
  Box,
} from "@mui/material";
import React, { useContext } from "react";
import { DeleteItemContext } from "../Context/DeleteItemContext";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import axios from "axios";

export default function DeleteItemDialog() {
  const deleteContext = useContext(DeleteItemContext);

  const handleDelete = async () => {
    if (deleteContext.deleteDialog.url) {
      try {
        await axios.get(deleteContext.deleteDialog.url);
        deleteContext.handleCloseDeleteDialog();
      } catch (error) {
        console.error("Error deleting product:", error);
        deleteContext.handleCloseDeleteDialog();
      }
    }
  };

  return (
    <Dialog
      open={deleteContext.deleteDialog.show}
      onClose={!deleteContext.deleteDialog.show}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Box display="flex" justifyContent="center">
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <DeleteIcon />
          </Avatar>
        </Box>
        <Typography variant="h6" align="center">
          Do you want to delete ?
        </Typography>
        <Typography variant="body1" align="center">
          {deleteContext.deleteDialog.title}
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => deleteContext.handleCloseDeleteDialog()}
        >
          No
        </Button>
        <Button
          variant="contained"
          color="error"
          autoFocus
          onClick={() => handleDelete()}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
