import { useContext, useEffect, useMemo, useState } from "react";

import {
  Grid,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Container,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteItemContext } from "../../Context/DeleteItemContext";

export default function Products() {
  const deleteContext = useContext(DeleteItemContext);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("/Products/Products.php");
        setProducts(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchProducts();
  }, []);

  const handleDeleteProduct = async (id, name) => {
    const productDeleteURL = `/Products/DeleteProduct.php?id=${id}`;
    deleteContext.handleShowDeleteDialog(name, productDeleteURL);
  };

  const columns = useMemo(
    () => [
      { field: "index", headerName: "Index", minWidth: 90, filterable: false },
      {
        field: "imageurl",
        headerName: "Image",
        minWidth: 90,
        renderCell: (params) => <Avatar src={params.row.imageurl} />,
        sortable: false,
        filterable: false,
      },
      { field: "name", headerName: "Product Name", flex: 1 },
      { field: "rate", headerName: "Rate", minWidth: 100 },
      { field: "gst", headerName: "GST", minWidth: 100 },
      {
        field: "rateincl",
        headerName: "Rate (Inclusive of Tax)",
        minWidth: 100,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <div>
            <Button
              component={Link}
              variant="contained"
              to={`/Products/EditProduct/${params.row.id}`}
            >
              <EditIcon />
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ ml: 1 }}
              onClick={() =>
                handleDeleteProduct(params.row.id, params.row.name)
              }
            >
              <DeleteIcon />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Button
            component={Link}
            to="/Products/AddProduct"
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        sx={{ mt: 1 }}
        columns={columns}
        rows={products}
        getRowId={(row) => row.id}
        initialState={{
          ...products.initialState,
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20]}
      />
    </Container>
  );
}
