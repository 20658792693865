import {
  Avatar,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { LockOutlined } from "@mui/icons-material";
import Logo from "../Assets/Logo/horizontal-logo.png";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .get(`/Users/Login.php?name=${name}&password=${password}`)
      .then((response) => {
        if (response.data.isauthenticated === true) {
          localStorage.setItem("userdata", response.data.data);
          localStorage.setItem("isauthenticated", true);
          enqueueSnackbar(response.data.message, { variant: "success" });
          navigate("/Products");
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(String(error), { variant: "error" });
        console.log(error);
      });
  };

  return (
    <Box sx={{ height: "100vh", backgroundColor: "#306AB2" }}>
      <Container
        component="main"
        maxWidth="xs"
        onSubmit={handleSubmit}
        sx={{ pt: 5 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: 3,
            padding: 5,
            backgroundColor: "white",
          }}
        >
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            Manage
          </Typography>

          <Avatar
            src={Logo}
            variant="square"
            sx={{ height: "72px", width: "215px" }}
          />

          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h6">
            SIGN-IN
          </Typography>

          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="User Name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
