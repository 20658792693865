import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AddUser() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("password", password);
    formData.append("role", role);
    formData.append("status", status);

    axios
      .post("/Users/AddUser.php", formData)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(String(error), { variant: "error" });
      });

    navigate("/Users");
  };

  return (
    <Container component="form" onSubmit={handleSubmit}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <TextField
            fullWidth
            label="User Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <TextField
            fullWidth
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container md={12} columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <FormControl fullWidth>
            <InputLabel>User Role</InputLabel>
            <Select
              label="User Role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Standard User">Standard User</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <FormControl fullWidth>
            <InputLabel>User Status</InputLabel>
            <Select
              label="User Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Button type="submit" sx={{ mt: 1 }} variant="contained">
        Submit
      </Button>
    </Container>
  );
}
