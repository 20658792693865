import { Container, Grid, Button } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { DeleteItemContext } from "../../Context/DeleteItemContext";

export default function Highlights() {
  const deleteContext = useContext(DeleteItemContext);

  const [hightlights, setHightlights] = useState([]);

  const handleDeleteHighlight = async (id, name) => {
    const highlightDeleteURL = `/Highlights/DeleteHightlight.php?id=${id}`;
    deleteContext.handleShowDeleteDialog(name, highlightDeleteURL);
  };

  useEffect(() => {
    const fetchHightlights = async () => {
      try {
        const response = await axios.get("/Highlights/Highlights.php");
        setHightlights(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchHightlights();
  }, []);

  const columns = useMemo(
    () => [
      { field: "index", headerName: "Index", minWidth: 90 },
      { field: "name", headerName: "Feature Name", flex: 1 },
      { field: "type", headerName: "Type", minWidth: 120 },
      { field: "description", headerName: "Description", minWidth: 180 },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 150,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: (params) => (
          <div>
            <Button
              component={Link}
              variant="contained"
              to={`/Hightlights/EditHightLight/${params.row.id}`}
            >
              <EditIcon />
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ ml: 1 }}
              onClick={() =>
                handleDeleteHighlight(params.row.id, params.row.name)
              }
            >
              <DeleteIcon />
            </Button>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} display="flex" justifyContent="end">
          <Button
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            LinkComponent={Link}
            to="/Hightlights/AddHightLight"
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        columns={columns}
        rows={hightlights}
        initialState={{
          ...hightlights.initialState,
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20]}
        sx={{ mt: 1 }}
      />
    </Container>
  );
}
