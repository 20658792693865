import { createContext, useContext, useState } from "react";

export const DeleteItemContext = createContext();

export const DeleteDialogState = (props) => {
  const [deleteDialog, setDeleteDialog] = useState({
    show: false,
    title: "",
    url: "",
  });

  const handleShowDeleteDialog = (title, url) => {
    setDeleteDialog({ show: true, title: title, url: url });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog({ show: false, title: "", url: "" });
  };

  return (
    <DeleteItemContext.Provider
      value={{ deleteDialog, handleShowDeleteDialog, handleCloseDeleteDialog }}
    >
      {props.children}
    </DeleteItemContext.Provider>
  );
};
