import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import {
  Box,
  FormControl,
  FormControlLabel,
  Container,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Avatar,
  Button,
  CardMedia,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AddHightlight() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [isImageFile, setIsImageFile] = useState(true);
  const [fileName, setFileName] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const handleFileChange = (e) => {
    setFilePreview(URL.createObjectURL(e.target.files[0]));
    setFileName(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("index", 1);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("type", type);
    formData.append("isimgfile", isImageFile);
    formData.append("feature_file", fileName);

    axios
      .post("/Highlights/AddHighlights.php", formData)
      .then((response) => {
        if (response.data.status === 200) {
          enqueueSnackbar(response.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(String(error), { variant: "error" });
      });

    navigate("/Hightlights");
  };

  return (
    <Container component="form" onSubmit={handleSubmit}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <TextField
            label="Feature Name"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              fullWidth
              required
              label="Type"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value="Upcomming">Upcomming</MenuItem>
              <MenuItem value="Present">Present</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <TextField
            label="Description"
            fullWidth
            multiline
            maxRows={6}
            minRows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={isImageFile ? "image" : "video"}
              name="radio-buttons-group"
              onChange={() => setIsImageFile(!isImageFile)}
            >
              <FormControlLabel
                value="image"
                control={<Radio />}
                label="Image"
                
              />
              <FormControlLabel
                value="video"
                control={<Radio />}
                label="Video"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {isImageFile ? (
              <Avatar
                variant="square"
                sx={{ height: 180, width: 180 }}
                src={filePreview}
              ></Avatar>
            ) : (
              <CardMedia
                component="video"
                sx={{ height: 180, width: 180 }}
                controls
                src={filePreview}
              ></CardMedia>
            )}
          </Box>
          <Box sx={{ mt: 1, mb: 1 }}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                accept={
                  isImageFile ? "image/png, image/jpeg, image/gif" : "video/*"
                }
                onChange={handleFileChange}
              />
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Button variant="contained" type="submit" sx={{ mt: 1 }}>
        Submit
      </Button>
    </Container>
  );
}
