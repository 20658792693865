import React, { useEffect } from "react";
import Sidebar from "./Components/Sidebar";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteItemDialog from "./Components/DeleteItemDialog";

export default function ProtectRoute(props) {
  const navigate = useNavigate();
  const { Component } = props;
  const drawerWidth = 270;
  
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isauthenticated");
    console.log(isAuthenticated);

    if (!isAuthenticated) {
      navigate("/");
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", minHeight: "100vh" }}>
      <Sidebar drawerWidth={drawerWidth} />
      <Box
        component="main"
        sx={{
          marginTop: 10,
          flexGrow: 1,
          width: `calc(100% - ${drawerWidth}px)`,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <DeleteItemDialog/>
        <Component />
      </Box>
    </Box>
  );
}
