import { Avatar, Box, Button, Container } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

export default function HelpCategories() {
  const [helpCategories, setHelpCategories] = useState([]);

  useEffect(() => {
    const fetchHelpCategories = async () => {
      try {
        const response = await axios.get("/HelpCenter/Category/Category.php");
        setHelpCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchHelpCategories();
  }, []);

  const columns = useMemo(
    () => [
      { field: "index", headerName: "Index", filterable: false },
      {
        field: "imageurl",
        headerName: "Image",
        minWidth: 90,
        renderCell: (params) => <Avatar src={params.row.imageurl} />,
        sortable: false,
        filterable: false,
      },
      {
        field: "name",
        headerName: "Help Category Name",
        flex: 1,
        minWidth: 300,
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <div>
            <Button
              component={Link}
              variant="contained"
              to={`/HelpCenter/HelpCategories/EditHelpCategory/${params.row.id}`}
            >
              <EditIcon />
            </Button>
            <Button variant="contained" color="error" sx={{ ml: 1 }}>
              <DeleteIcon />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Container>
      <Button
        component={Link}
        to="/HelpCenter/HelpCategories/AddHelpCategory"
        variant="contained"
        size="large"
        startIcon={<AddIcon />}
      >
        Add
      </Button>
      <Box sx={{ mt: 3 }}>
        <DataGrid
          columns={columns}
          rows={helpCategories}
          getRowId={(row) => row.id}
          initialState={{
            ...helpCategories.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20]}
        />
      </Box>
    </Container>
  );
}
